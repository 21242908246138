import type {
  Database,
  Tables,
  TablesInsert,
  TablesUpdate
} from '~~/types/supabase'
import type { AtLeast, Maybe } from '~~/types/utils'

type Asset = Tables<'assets'>
type AssetCategory = Tables<'asset_categories'>
type AssetFile = Tables<'asset_files'>

type AssetWithCategoryAndFiles = Asset & {
  asset_categories: Maybe<Pick<AssetCategory, 'name'>>
  asset_files: Array<Pick<AssetFile, 'id' | 'file_name'>>
}

export type AssetCategoryWithSharedUsers = AssetCategory & {
  users?: Array<Pick<Tables<'asset_category_users'>, 'profile_id'>>
}

export type FormattedAssetWithCategoryAndFiles = AssetWithCategoryAndFiles & {
  category_name: string | null
}

export default function useAssets() {
  const supabase = useSupabaseClient<Database>()

  // Shared state for asset categories
  const categories = useState<AssetCategoryWithSharedUsers[]>(
    'assetCategories',
    () => []
  )

  const getAssets = async ({
    categoryId,
    searchText,
    featured
  }: {
    categoryId?: number
    searchText?: string
    featured?: boolean
  } = {}): Promise<FormattedAssetWithCategoryAndFiles[]> => {
    const query = supabase
      .from('assets')
      .select('*, asset_categories(name), asset_files(id, file_name)')
      .order('name', { ascending: true })

    if (categoryId) {
      query.eq('category_id', categoryId)
    }

    if (searchText) {
      query.or(`name.ilike.%${searchText}%, description.ilike.%${searchText}%`)
    }

    if (featured !== undefined) {
      query.eq('featured', featured)
    }

    const { data, error } = await query.returns<AssetWithCategoryAndFiles[]>()

    if (error) throw error

    return data.map(formatAsset)
  }

  const getAssetForEditById = async (
    id: number
  ): Promise<
    FormattedAssetWithCategoryAndFiles & {
      users: Array<Pick<Tables<'asset_users'>, 'profile_id'>>
    }
  > => {
    const { data, error } = await supabase
      .from('assets')
      .select(
        '*, asset_categories(name), asset_files(id, file_name), users:asset_users(profile_id)'
      )
      .eq('id', id)
      .returns<
        Array<
          AssetWithCategoryAndFiles & {
            users: Array<Pick<Tables<'asset_users'>, 'profile_id'>>
          }
        >
      >()
      .single()

    if (error) throw error

    return formatAsset(data)
  }

  const getAssetByIdOrSlug = async (
    identifier: number | string
  ): Promise<FormattedAssetWithCategoryAndFiles> => {
    let query = supabase
      .from('assets')
      .select('*, asset_categories(name), asset_files(id, file_name)')

    if (typeof identifier === 'number') {
      query = query.eq('id', identifier)
    } else {
      query = query.eq('slug', identifier)
    }

    const { data, error } = await query
      .returns<AssetWithCategoryAndFiles[]>()
      .single()

    if (error) throw error

    return formatAsset(data)
  }

  const createAsset = async (asset: TablesInsert<'assets'>) => {
    const { data, error } = await supabase
      .from('assets')
      .insert(asset)
      .select('*, asset_categories(name)')
      .single()

    if (error) throw error

    return formatAsset(data)
  }

  const updateAsset = async (id: number, updates: TablesUpdate<'assets'>) => {
    const { data, error } = await supabase
      .from('assets')
      .update(updates)
      .eq('id', id)
      .select('*, asset_categories(name)')
      .single()

    if (error) throw error

    return formatAsset(data)
  }

  const deleteAsset = async (id: number) => {
    // Get files associated with asset, delete from storage
    const { data: files_to_delete } = await supabase
      .from('asset_files')
      .select('file_name')
      .eq('asset_id', id)

    // Convert files names to Supabase formatted array, delete them
    if (files_to_delete && files_to_delete.length > 0) {
      const fileNames = files_to_delete.map(file => file.file_name)
      const { error } = await supabase.storage
        .from('asset_files')
        .remove(fileNames)

      if (error) throw error
    }

    // After files are deleted, delete the asset
    const { error } = await supabase.from('assets').delete().eq('id', id)

    if (error) throw error
  }

  const getAssetFiles = async (assetId: number) => {
    const { data, error } = await supabase
      .from('asset_files')
      .select('*')
      .eq('asset_id', assetId)

    if (error) throw error

    return data
  }

  const deleteAssetFile = async (id: number) => {
    const { error } = await supabase.from('asset_files').delete().eq('id', id)

    if (error) throw error
  }

  /**
   * Fetches the list of categories.
   *
   * Because this function is used to seed a global state value for both read and write actions, it must always query
   * data that is only used in a write context, such as the list of allowed users.
   */
  const getAssetCategories = async () => {
    const { data, error } = await supabase
      .from('asset_categories')
      .select('*, users:asset_category_users(profile_id)')

    if (error) throw error

    return (categories.value = data)
  }

  const getAssetCategory = async (id: number) => {
    const { data, error } = await supabase
      .from('asset_categories')
      .select('*, users:asset_category_users(profile_id)')
      .eq('id', id)
      .single()

    if (error) throw error

    const index = categories.value.findIndex(item => item.id === data.id)

    if (index === -1) {
      categories.value.push(data)
    } else {
      categories.value[index] = data
    }

    return data
  }

  /**
   * Fetch a list of asset categories to use in a select list
   */
  const getAssetCategoryOptionList = async () => {
    const { data, error } = await supabase
      .from('asset_categories')
      .select('id, name')
      .order('name', { ascending: true })

    if (error) throw error

    return data
  }

  const getAssetCategoryBySlug = async (slug: string) => {
    const { data, error } = await supabase
      .from('asset_categories')
      .select('*')
      .eq('slug', slug)
      .single()

    if (error) throw error

    return data
  }

  const createCategory = async (category: TablesInsert<'asset_categories'>) => {
    const { data, error } = await supabase
      .from('asset_categories')
      .insert(category)
      .select('*, users:asset_category_users(profile_id)')
      .single()

    if (error) throw error

    categories.value.push(data)

    return data
  }

  const deleteCategory = async (id: number) => {
    const { error } = await supabase
      .from('asset_categories')
      .delete()
      .eq('id', id)

    if (error) throw error
  }

  const updateCategories = async (
    updates: Array<TablesInsert<'asset_categories'>>
  ) => {
    // Helper function to pick only updatable fields
    const pickUpdatableFields = (
      category: TablesInsert<'asset_categories'>
    ) => {
      const {
        id,
        name,
        slug,
        parent_id,
        role,
        buyer_group,
        published,
        sort_order,
        icon
      } = category
      return {
        id,
        name,
        slug,
        parent_id,
        role,
        buyer_group,
        published,
        sort_order,
        icon
      }
    }

    // Filter out any updates without an id and pick only updatable fields
    const validUpdates = updates
      .filter(update => update.id !== undefined)
      .map(pickUpdatableFields)

    if (validUpdates.length === 0) {
      console.warn('No valid updates to perform')
      return categories.value
    }
    // Perform bulk update
    const { data, error } = await supabase
      .from('asset_categories')
      .upsert(validUpdates, { onConflict: 'id' })
      .select('*, users:asset_category_users(profile_id)')

    if (error) {
      console.error('Error updating categories:', error)
      throw error
    }

    if (data) {
      // Update the local state
      const updatedMap = new Map(data.map(item => [item.id, item]))
      categories.value = categories.value.map(
        category => updatedMap.get(category.id) || category
      )
    }

    return categories.value
  }

  const updateSharedAssetList = async (
    assetId: number,
    profileIds: string[]
  ) => {
    const { error } = await supabase.rpc('replace_asset_shared_users', {
      asset: assetId,
      profiles: profileIds
    })

    if (error) throw error
  }

  const updateSharedAssetCategoryList = async (
    assetCategoryId: number,
    profileIds: string[]
  ) => {
    const { error } = await supabase.rpc(
      'replace_asset_category_shared_users',
      {
        asset_category: assetCategoryId,
        profiles: profileIds
      }
    )

    if (error) throw error

    await getAssetCategory(assetCategoryId)
  }

  function formatAsset<
    T extends Asset & {
      asset_categories: Maybe<AtLeast<AssetCategory, 'name'>>
    }
  >(
    asset: T
  ): T & {
    category_name: string | null
  } {
    return {
      ...asset,
      image_preview: asset.image_preview
        ? getSupabasePrivateImgPath({
            bucket: 'asset_previews',
            object: asset.image_preview
          })
        : null,
      category_name: asset.asset_categories?.name ?? null
    }
  }

  return {
    getAssets,
    getAssetByIdOrSlug,
    getAssetForEditById,
    createAsset,
    createCategory,
    updateAsset,
    updateCategories,
    deleteAsset,
    deleteCategory,
    getAssetFiles,
    deleteAssetFile,
    getAssetCategories,
    getAssetCategoryBySlug,
    getAssetCategoryOptionList,
    updateSharedAssetList,
    updateSharedAssetCategoryList,
    categories
  }
}
